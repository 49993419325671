import { addCollection } from '@iconify-icon/react/dist/iconify.mjs';

fetch('/icon-set.json')
  .then((response) => response.json())
  .then((data) => {
    addCollection(data);
  })
  .catch((error) => {
    console.error('Error loading icon set:', error);
  });
